

const components:any = {
  core: {
    label: 'Screentone Core',
    gitUrl: 'https://github.com/newscorp-ghfb/screentone-core/',
    docs: '/docs'
  },
  'auth-wrapper': {
    label: 'Auth Wrapper for React',
    description:
      'A Screentone add-on component that handles routing and okta authentication for React apps using React Router.',
    docs: '/addons/auth-wrapper',
    gitUrl:
      'https://github.com/newscorp-ghfb/screentone-auth-mono/tree/develop/packages/auth-wrapper-react',
    isAddon: true,
  },
  'auth-wrapper-nextjs': {
    label: 'Auth Wrapper for NextJS',
    description:
      'A Screentone add-on component that handles routing and okta authentication for you for NextJS apps.',
    docs: '/addons/auth-wrapper-nextjs',
    gitUrl:
      'https://github.com/newscorp-ghfb/screentone-auth-mono/tree/develop/packages/auth-wrapper-nextjs',
    isAddon: true,
  },

  'auth-wrapper-express': {
    label: 'Auth Wrapper for Express',
    description:
      'A Screentone add-on component that provides helper functions for Express.',
    docs: '/addons/auth-wrapper-express',
    gitUrl:
      'https://github.com/newscorp-ghfb/screentone-auth-mono/tree/develop/packages/auth-wrapper-express',
    isAddon: true,
  },
  autocomplete: {
    label: 'Auto Complete',
    description: 'An input for storing a list of selected values, with an optional drop-down for value selection.',
    docs: '/addons/autocomplete',
    gitUrl: 'https://github.com/newscorp-ghfb/screentone-addon-autocomplete',
    isAddon: true,
  },
  calendar: {
    label: 'Calendar',
    docs: '/addons/calendar',
    description: 'A versatile calendar for selecting a date or a range of dates and optionally a time.',
    gitUrl: 'https://github.com/newscorp-ghfb/screentone-addon-calendar',
    isAddon: true,
  },
  'color-picker': {
    label: 'Color Picker',
    description: 'A simple color-picker that lets users select a color from a limited palette with a single click.',
    docs: '/addons/color-picker',
    gitUrl: 'https://github.com/newscorp-ghfb/screentone-addon-color-picker',
    isAddon: true,
  }
}

export default components;
