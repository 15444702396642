import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Divider, Grid, PageWrapper, Textblock, Illustration } from '@screentone/core';
import Markdown from 'markdown-to-jsx';
import components from '../../components';

const Addons: React.FC<RouteComponentProps> = () => {

  const addons = Object.keys(components).map((i) => {
    const item = components[i];
    if (!item.isAddon) {
      return '';
    }
    return `
### ${item.label}

${item.description}
${item.docs ? `
- [Documents](${item.docs})` : ''}${item.example ? `
- [Example](${item.example})` : ''}${item.gitUrl ? `
- [GitHub](${item.gitUrl})` : ''}
`
  });

  const markdown = `
# Add-ons

## Additional components and functionality that is not included in the core Screentone package.
Screentone add-ons are published as separate npm packages under the \`@screentone\` namespace, e.g. \`@screentone/addon-calendar\`.


___


${addons.join('')}

___

## Creating New Components
Interested in creating an Add-On? [Our instructions are here](https://github.com/newscorp-ghfb/screentone-core/wiki/How-to-publish-a-Screentone-add-on-component).

Before you start, please speak to a team member, so we can check that someone else is not working on that feature yet.
`;

  return (
    <PageWrapper style={{ margin: 'var(--st-spacer-sm) auto var(--st-spacer-xl) ' }}>
      <Button tertiary icon="arrow-left"componentEl="a" href="/">Back</Button><Divider />
        <Grid>
        <Grid.Row bottom="xs">
          <Grid.Col xs={12} sm={6}>
            <Textblock>
              <Markdown options={{wrapper: React.Fragment}}>{markdown}</Markdown>
            </Textblock>
          </Grid.Col>
          <Grid.Col xs={6} sm={4} xsOffset={3} smOffset={1}>
            <Illustration style={{maxWidth: '100%'}} name="collect" />
          </Grid.Col>
          </Grid.Row>
        </Grid>
      </PageWrapper>
    );
  
};
export default Addons;
