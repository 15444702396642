import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Grid, PageWrapper, Illustration, Typography, Textblock } from '@screentone/core';
import components from '../../components';

const Home: React.FC<RouteComponentProps> = () => {

  return (
      <PageWrapper className="pagewrapper--home">
        <Grid>
        <Grid.Row between="xs">
          <Grid.Col xs={12} sm={9}>
              <Typography size="xxl" margin={{ top: 'xl', bottom: 'sm' }} componentEl="h1">
                Screentone
              </Typography>
              <Typography size="lg" color="asphalt" margin={{ top: 'sm', bottom: 'lg'}} componentEl="h2">
                Resources, tooling, and design guidelines for building websites with Screentone, the Dow&nbsp;Jones Editorial Tools design system.
              </Typography>
            </Grid.Col>
        </Grid.Row>

        <Grid.Row>
          <Grid.Col xs={12} sm={6}>
            <Textblock>
              <p>Our goal is to create a system that enables us to build consistent user experiences with ease,
            yet with enough flexibility to support the broad range of tools for the WSJ newsroom.</p>
              <p>This style guide is living documentation that will be updated as we continue to improve and
            evolve our design system.</p>
              <p>&nbsp;</p>
              <h3><a href="/docs">User Guides</a></h3>
              <p>The design system documentation in Storybook. Check here for design guidance and code reference.</p>
              <p>&nbsp;</p>
              <h3><a href="/addons">Add-ons</a></h3>
              <p>Additional components and functionality that is not included in the core Screentone package.</p>
              <ul>
                {Object.keys(components).map((i) => {
                  const item = components[i];
                  if (!item.isAddon) {
                    return null;
                  }
                  return (
                    <li key={i}>
                      <a href={item.docs}>{item.label}</a>
                    </li>
                  );
                })}
              </ul>
              {/*

              <h3><a href="/updates">Updates</a></h3>
              <p>The continuing story of the design system production. Read about what’s new.</p>

              <h3><a href="/contribute">How to Contribute</a></h3>
              <p>Learn how to join our project and help us move Screentone forward.</p>
              */}     
            </Textblock>
          </Grid.Col>
          <Grid.Col xs={6} sm={4} xsOffset={3} smOffset={1}>
            <Illustration style={{maxWidth: '100%'}} name="webpage" />
          </Grid.Col>
          </Grid.Row>
        </Grid>
      </PageWrapper>
    );
  
};
export default Home;
