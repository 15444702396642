import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useLocation, RouteComponentProps } from 'react-router-dom';
import { Box, Button, Loader, Token, Typography } from '@screentone/core';

import components from './components';

const BASE_ENV_DEV = 'https://screentone.dev.djnews.tools/';

function getBaseUrl() {
  const origin = window.location.origin;

  if(origin.indexOf('localhost') > -1){
    return BASE_ENV_DEV;
  }

  return `${origin}/`;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Home: React.FC<RouteComponentProps> = () => {
  const { addon = 'core', version = 'latest' } = useParams<{ addon: string, version?: string }>();
  const querystring = useQuery();
  const [ready, setReady] = useState(false);
  const [url, setUrl] = useState(getBaseUrl());

  const location = useLocation();
  const showAnnouncementBar = (version !== 'latest');

  // update browser url to add querystring with current Storybook path
  // when storybook iframe sends a message
  const handleMessage = useCallback(
    (e) => {
      if (e.data.type === 'docsRendered' || e.data.type === 'storyRendered') {
        if (!e.data.path || e.data.path === '') return true;

        if (e.data.path.toString() === querystring.get('path')?.toString()) return null;

        let currentUrlParams = new URLSearchParams(window.location.search);
        currentUrlParams.set('path', e.data.path);
 
        window.history.pushState(null, '', `${location.pathname}?${decodeURIComponent(currentUrlParams.toString())}`);
      }
    }
    , [location, querystring]
  );

  useEffect(() => {
    window.addEventListener("message", handleMessage, false);
    return () => window.removeEventListener("message", handleMessage);
  }, [handleMessage]);

  // let iframesDisplay = 'none';
  // let loaderDisplay = 'block';

  useEffect(() => {
    function getNewUrl() {
      const addonParam = addon === 'core' ? addon : `addon/addon-${addon}`
      const newIframeUrl = `${getBaseUrl()}storybooks/${addonParam}/${version}/index.html${querystring.get('path') ? `?path=${querystring.get('path')}` : ''}`;
      setUrl(newIframeUrl);
    }
    getNewUrl();
  }, [addon, querystring, url, version]);

  const onLoadHandler = () => {
    setReady(true);
  }

  return (
    <>
      <Box
        fullWidth
        attached="bottom"
        padding={{ all: 'xs' }}
        margin={{ all: 'none' }}
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Typography variant="label2" margin={{ vertical: 'xs', horizontal: 'md' }}>
          {components[addon].label}{' '}<Token>{version.toUpperCase()}</Token>
          {' '}
          {showAnnouncementBar && components[addon] && (
            <Token
              componentEl="a"
              href="./"
              color="blurple"
            >View latest version</Token>
          )}
        </Typography>
        <Button
          componentEl="a"
          href={components[addon].gitUrl}
          target="_blank"
          rel="noopener noreferrer"
          tertiary
          icon="github"
          margin={{ right: 'mlg' }}
        >{components[addon].gitUrl}</Button>
      </Box>
      <iframe
        title="contents"
        src={url}
        sandbox="allow-scripts allow-same-origin allow-top-navigation allow-forms allow-popups allow-pointer-lock allow-popups-to-escape-sandbox"
        style={{
          position: "fixed",
          bottom: 0,
          height: 'calc(100vh - 100px)',
          display: ready ? 'block' : 'none'
        }}
        width="100%"
        frameBorder="0"
        onLoad={onLoadHandler}
      />
      <Loader style={{
        display: ready ? 'none' : 'block',
        margin: 'auto',
        marginTop: '100px'
      }} />
    </>
  )
};
export default Home;