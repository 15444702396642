let CLIENT_ID = '0oax65byb7hTt5Fxg0h7';
let ISSUER = 'https://newscorp.oktapreview.com/oauth2/ausx65glsfuVlEzcu0h7';
let ENV = 'local';
const { origin } = window.location;

if (process.env.REACT_APP_STAGE === 'int') {
  CLIENT_ID = '0oaz19v6rlxqs9QTf0x7';
  ISSUER = 'https://newscorp.okta.com/oauth2/ausqra63fozF7py460x7';
  ENV = '';
}

if (process.env.REACT_APP_STAGE === 'dev') {
  CLIENT_ID = '0oaqra60b794cZvPX0x7';
  ISSUER = 'https://newscorp.okta.com/oauth2/ausqra63fozF7py460x7';
  ENV = 'dev';
}

if (process.env.REACT_APP_STAGE === 'prod') {
  CLIENT_ID = '0oaqqvzkbnNRaF24t0x7';
  ISSUER = 'https://newscorp.okta.com/oauth2/ausqqvyjmtaU66f6w0x7';
  ENV = '';
}

const config = {
  oktaAuthService: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: `${origin}/implicit/callback`,
    tokenManager: {
      storage: 'cookie',
    }
  },
  app: {
    name: 'Screentone Design System',
    key: 'screentone-design-system',
    env: ENV,
    slack: {
      name: 'screentone-design-system',
      url: 'https://dowjones.slack.com/archives/CDY82284B',
    },
    requestAccess: {
      url: 'https://hooks.slack.com/services/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',
    },
  },
  headerBar: {
    links: [
      {
        href: '/docs',
        text: 'User Guides',
      },
      {
        href: '/addons',
        text: 'Add-ons',
      },
    ]
  }
};

export default config;