import React, {useEffect, useState} from 'react';
import { IconSymbol } from '@screentone/core';
import { AuthRouter, ProtectedRoute, useAuth } from '@screentone/addon-auth-wrapper';
import clone from 'lodash/clone';
import find from 'lodash/find';

import config from './config';
import Home from './Pages/Home';
import Authenticated from './Authenticated';
import Addons from './Pages/Addons';
import Updates from './Authenticated';
import Contribute from './Pages/Contribute';
import components from './components';

const HeaderBar = () => {
  const { authState, login } = useAuth();
  const { isPending, isAuthenticated } = authState;
  const [header, setHeader] = useState(config.headerBar);

    useEffect(() => {
      if (!isPending && !isAuthenticated) {
        let headerBarObj = clone(config.headerBar);
        const hasLoginLink = find(headerBarObj.links, (o) => {
          return o.text === 'Login';
        }); 
        if (!hasLoginLink) {
        headerBarObj.links.push({
          text: 'Login',
          // @ts-ignore
          onClick: () => login(),
          primary: true
        })
        setHeader(headerBarObj);
        }
      }
    }, [isAuthenticated, isPending, login]);
  return [header]
}

const addonPath = Object.keys(components).map((i) => {
  const item = components[i];
  if (item.isAddon) {
    return i;
  }
  return null;
})
  .filter((el) => {
    return el != null;
  });

function App() {
  return (
    <React.Fragment>
      <AuthRouter
        app={config.app}
        headerBarHook={HeaderBar}
        authConfig={config.oktaAuthService}
        homePage={Home}
        loginPage={Home}
      >
        <ProtectedRoute exact path="/updates" component={Updates} />
        <ProtectedRoute exact path="/contribute/:page?" component={Contribute} />
        <ProtectedRoute exact path="/docs" component={Authenticated} />
        <ProtectedRoute exact path="/docs/:version([A-Za-z0-9\.\-]+)" component={Authenticated} />
        <ProtectedRoute exact path="/addons" component={Addons} />
        <ProtectedRoute exact path={`/addons/:addon(${addonPath.join('|')})`} component={Authenticated} />
        <ProtectedRoute exact path={`/addons/:addon(${addonPath.join('|')})/:version([A-Za-z0-9.-]+)`} component={Authenticated} />
      </AuthRouter>
      <IconSymbol />
    </React.Fragment>
  );
}

export default App;
