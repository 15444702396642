import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useParams, Link} from 'react-router-dom';
import { Grid, PageWrapper, Sidebar, SideNav, Textblock } from '@screentone/core';
import Markdown from 'markdown-to-jsx';

// import { useAuth } from '@screentone/addon-auth-wrapper';

const Contribute: React.FC<RouteComponentProps> = () => {
  const { page } = useParams<{ page: string }>();
  const [md, setMd] = useState('');

  // const { app, authState, authService } = useAuth();


  useEffect(() => {
    async function geMd() {
   
      const readmePath = require(`./md/${page || 'Home'}.md`);

      fetch(readmePath)
        .then(response => {
          return response.text()
        })
        .then(text => {
          console.log('text: ', text);
          setMd(text);
        })
    }

    geMd()
  }, [page]);


  return (<React.Fragment>
    <Sidebar type="permanent">
      <Sidebar.Header>
        <SideNav>
          <SideNav.Item componentEl={Link} active={!page} to="/contribute">How to Contribute</SideNav.Item>
        </SideNav>
      </Sidebar.Header>
      <Sidebar.Section>
        <SideNav>
          <SideNav.Section label="Best Practices">
            <SideNav.Item componentEl={Link} to="/contribute/Philosophy">Philosophy</SideNav.Item>
            <SideNav.Item componentEl={Link} to="/contribute/Best-Practices:-Using-existing-components">Using Existing Components</SideNav.Item>
            <SideNav.Item componentEl={Link} to="/contribute/Best-Practices:-HTML">HTML</SideNav.Item>
            <SideNav.Item componentEl={Link} to="/contribute/Best-Practices:-CSS">CSS</SideNav.Item>
          </SideNav.Section>
    
          <SideNav.Section label="Tutorials">
            <SideNav.Item componentEl={Link} to="/contribute/How-to-create-a-new-component-1:-Basic-component">How to create a new component</SideNav.Item>
            <SideNav.Item componentEl={Link} to="/contribute/Component-Patterns">Component Patterns</SideNav.Item>
          </SideNav.Section>


          <SideNav.Section label="How to update Screentone">
            <SideNav.Item componentEl={Link} to="/contribute/Git-Flow">Git Flow, or how to name your branch</SideNav.Item>
            <SideNav.Item componentEl={Link} to="/contribute/How to deploy a new version">How-to-review-a-Pull-Request</SideNav.Item>
            <SideNav.Item componentEl={Link} to="/contribute/How-to-deploy-new-version">How to deploy a new version</SideNav.Item>
            <SideNav.Item componentEl={Link} to="/contribute/How-to-Add-or-Update-SVG-Icons">How to update SVG Icons</SideNav.Item>
            <SideNav.Item componentEl={Link} to="/contribute/How-to-add-or-update-design-tokens">How to update design tokens</SideNav.Item>
            <SideNav.Item componentEl={Link} to="/contribute/How-to-publish-a-Screentone-add-on-component">How to create add-on components</SideNav.Item>
          </SideNav.Section>
        </SideNav>
      </Sidebar.Section>
      {/* <Sidebar.Footer>
        <SideNav>
          <SideNav.Item componentEl="button" icon="plus-circle">New Note</SideNav.Item>
        </SideNav>
      </Sidebar.Footer> */}
    </Sidebar>
    <Sidebar.Page>
      <PageWrapper>
        <Grid>
          <Grid.Row>
            <Grid.Col sm={12} mdOffset={1} md={10} lgOffset={2} lg={8}>
              <Textblock> <Markdown>{md}</Markdown></Textblock>
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </PageWrapper>
    </Sidebar.Page>
    </React.Fragment>
    );
  
};
export default Contribute;
